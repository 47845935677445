import React, { ButtonHTMLAttributes, SyntheticEvent } from 'react';
import { Btn } from './Button.css';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Router } from '@utils/i18n';
import { UrlObject } from 'url';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    /** Style du bouton */
    variant?:
        | 'action'
        | 'normal'
        | 'clear'
        | 'outline'
        | 'delete'
        | 'red'
        | 'orange'
        | 'clear-white'
        | 'clear-green'
        | 'outline-white'
        | 'normal-white';
    /** Icone à placer à droite */
    rightIcon?: IconName;
    /** Icone à placer à gauche */
    leftIcon?: IconName;
    /** Bouton ne contenant qu'une icone (réduit le padding pour avoir un bouton parfaitement rond) */
    onlyIcon?: boolean;
    /** Montre l'état de chargement */
    isLoading?: boolean;
    /** Texte (optionnel) de l'état de chargement */
    loadingText?: string;
    /** url */
    href?: string;
    /** Le bouton prendra toute la largeur */
    fullWidth?: boolean;
    /** Créer un boutton de retour */
    back?: boolean;
    /** Créer un boutton de suivant */
    next?: boolean;
    /** Attribut servant à créer un lien externe au site */
    isExternal?: boolean;
    /** Attribut servant à créer un lien externe au site */
    blink?: boolean;
    /** Afficher la petite fleche précédente */
    backIco?: boolean;
    /** Taille */
    size?: 'small' | 'large';
    /** Actual path (including the query) shown in the browser */
    asPath?: string | UrlObject;
}

const Button: React.FC<IButton> = props => {
    const {
        children,
        variant,
        leftIcon,
        rightIcon,
        onlyIcon,
        isLoading,
        loadingText,
        href,
        fullWidth,
        back,
        next,
        isExternal,
        blink,
        backIco,
        size,
        asPath
    } = props;

    let variantType = '';
    if (variant == 'action') {
        variantType = 'button-action';
    } else if (variant == 'clear') {
        variantType = 'button-clear';
    } else if (variant == 'outline') {
        variantType = 'button-outline';
    } else if (variant == 'delete') {
        variantType = 'button-delete';
    } else if (variant == 'red') {
        variantType = 'button-red';
    } else if (variant == 'orange') {
        variantType = 'button-orange';
    } else if (variant == 'clear-white') {
        variantType = 'button-clear-white';
    } else if (variant == 'clear-green') {
        variantType = 'button-clear-green';
    } else if (variant == 'outline-white') {
        variantType = 'button-outline-white';
    } else if (variant == 'normal-white') {
        variantType = 'button-normal-white';
    } else if (variant == 'normal' || !variant) {
        variantType = 'button-normal';
    }

    let leftIco;
    let rightIco;
    if (leftIcon) {
        let iconProps: IconProp;
        variantType == 'button-normal' ||
        variantType == 'button-action' ||
        variantType == 'button-red' ||
        variantType == 'button-orange' ||
        variantType == 'button-normal-white'
            ? (iconProps = ['fas', leftIcon])
            : (iconProps = ['far', leftIcon]);
        if (!leftIco) {
            leftIco = <FontAwesomeIcon icon={iconProps} />;
        }
    }

    const backIcon = backIco && (
        <FontAwesomeIcon icon={['fas', 'caret-left']} />
    );
    const nextIco = next && <FontAwesomeIcon icon={['fas', 'caret-right']} />;

    if (rightIcon) {
        let iconProps: IconProp;
        variantType == 'button-normal' ||
        variantType == 'button-action' ||
        variantType == 'button-red' ||
        variantType == 'button-orange' ||
        variantType == 'button-normal-white'
            ? (iconProps = ['fas', rightIcon])
            : (iconProps = ['far', rightIcon]);
        if (!rightIco) {
            rightIco = <FontAwesomeIcon icon={iconProps} />;
        }
    }

    let appearAsLink: 'a' | 'button';
    if (href) {
        appearAsLink = 'a';
    } else {
        appearAsLink = 'button';
    }

    const fullWidthClassName = fullWidth ? 'button-full-width' : '';
    const blinkingClassName = blink ? 'button-blinking' : '';
    const onlyIconClassName = onlyIcon ? 'button-icon-only' : '';
    const sizeClassName =
        size == 'large'
            ? 'button-large'
            : size == 'small'
            ? 'button-small'
            : '';

    const handleClick = (e: SyntheticEvent) => {
        if (back && !onclick) {
            Router.back();
        } else if (href && !isExternal) {
            e.preventDefault();
            Router.push(href, asPath);
        } else if (href && isExternal) {
            e.preventDefault();
            window.open(href, '_blank');
        } else return undefined;
    };

    return (
        <Btn
            as={appearAsLink}
            className={`${variantType} ${onlyIconClassName} ${fullWidthClassName} ${blinkingClassName} ${sizeClassName}`}
            disabled={isLoading}
            onClick={handleClick}
            {...props}
        >
            {isLoading ? (
                <>
                    <FontAwesomeIcon icon='spinner-third' spin />
                    {loadingText && <span>{loadingText}</span>}
                </>
            ) : (
                <>
                    {leftIco}
                    {backIcon} <span>{children}</span> {nextIco} {rightIco}
                </>
            )}
        </Btn>
    );
};

export default Button;
