export const bp = {
    xs: 'max-width:420px',
    sm: 'max-width:640px',
    md: 'max-width:768px',
    lg: 'max-width:1024px',
    xl: 'max-width:1280px'
};

export const colors = {
    action: '#00c090',
    main: '#1C287F',
    error: '#D80027',
    success: '#03CE9C',
    darkblue: '#131A50',
    blue: '#3A90E1',
    gray20: '#E3E4E8',
    gray40: '#C5C6D3',
    gray60: '#8589AD',
    gray80: '#424770'
};

export const background = {
    blue: '#1C287F',
    lightgray: '#F0F2F5',
    darkblue: '#131A50'
};

export const form = {
    fontSizeInput: '15px',
    fontSizeSelectDropdown: '14px',
    borderDefault: '#e5e5e5',
    borderFocus: '#3a90e1',
    borderError: '#D80027',
    selectedItem: '#3a90e1',
    boxShadowFocus: '#9dc8f0',
    boxShadowError: '#ec8093',
    highlightMatch: '#fbe4c3',
    textDefault: '#0a0e27',
    textInactive: '#858794',
    tagBackgroundDefault: 'transparent',
    tagTextDefault: '#000000',
    textSizeDefault: '15px',
    radioBtnTextActive: '#FFFFFF',
    radioBtnTextHover: '#1C287F',
    radioBtnBgActive: '#1C287F',
    radioBtnBgHover: 'rgba(28, 40, 127, 0.1)',
    radioBtnBorderRadius: '22px',
    radioBtnHeight: '37px',
    radioBtnPaddingX: '20px',
    placeholder: '#9d9ea9',
    countryPhoneSelector: '#f9f9f9',
    countryPhoneSelectorHover: '#f0f1f5',
    countryPhoneSelectorText: '#9d9ea9',
    checkboxWhiteBorderDefault: 'rgba(255, 255, 255, 0.1)',
    checkboxWhiteBorderHover: '#3A90E1',
    checkboxWhiteboxShadowFocus: '#224F84'
};
