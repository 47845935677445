import styled, { keyframes } from 'styled-components';
import { colors, bp } from '@components/_struct/variables';

const blinking = keyframes`
    50% {
        opacity:1
    }
`;

export const Btn = styled.button`
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 36px;
    height: 36px;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    border: 0 none;
    border-radius: 20px;
    transition: all 0.15s ease;
    outline: 0;
    &.button-large {
        font-size: 20px;
        line-height: 50px;
        height: 50px;
        border-radius: 40px;
    }
    &.button-small {
        font-size: 12px;
        line-height: 28px;
        height: 28px;
        border-radius: 30px;
        padding: 0 10px;
    }
    :-moz-focusring {
        outline: 0;
    }
    svg {
        font-size: 20px;
        animation-duration: 0.8s;
        max-height: 100%;
    }
    svg + span,
    span + svg {
        margin-left: 8px;
    }
    span {
        white-space: nowrap;
        font-weight: bold;
    }
    &.button-full-width {
        width: 100%;
        justify-content: center;
    }
    &.button-icon-only {
        padding: 0;
        width: 36px;
        border-radius: 50%;
        justify-content: center;
        svg + span,
        span + svg {
            margin-left: 0;
        }
        svg {
            font-size: 16px;
        }
        &::before {
            border-radius: 50%;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
        }
        &.button-small {
            width: 28px;
        }
        &.button-large {
            width: 50px;
        }
    }
    &.button-normal {
        background: #1c287f;
        color: #fff;
        &::before {
            box-shadow: 0 0 0 2px #1c287f;
        }
    }
    &.button-normal-white {
        background: #fff;
        color: #1c287f;
        &::before {
            box-shadow: 0 0 0 2px #fff;
        }
    }
    &.button-action {
        background: #00c090;
        color: #fff;
        &::before {
            box-shadow: 0 0 0 2px #00b78a;
        }
    }
    &.button-red {
        background: #b80021;
        color: #fff;
        &::before {
            box-shadow: 0 0 0 2px #b80021;
        }
    }
    &.button-orange {
        background: #ff8d27;
        color: #fff;
        &::before {
            box-shadow: 0 0 0 2px #ff8d27;
        }
    }
    &.button-clear,
    &.button-outline {
        background: transparent;
        color: #1c287f;
        &::before {
            box-shadow: 0 0 0 2px rgba(28, 40, 127, 0.5);
        }
    }
    &.button-delete {
        background: transparent;
        color: #d80027;
        &::before {
            box-shadow: 0 0 0 2px rgba(218, 0, 38, 0.5);
        }
    }
    &.button-clear-green {
        background: transparent;
        color: ${colors.success};
        &::before {
            box-shadow: 0 0 0 2px rgb(0, 192, 144, 0.5);
        }
    }
    &.button-clear-white {
        background: transparent;
        color: #fff;
        &::before {
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
        }
    }
    &.button-outline-white {
        background: transparent;
        color: #fff;
        &::before {
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 1px;
        right: 1px;
        bottom: 0px;
        border: 0;
        border-radius: 20px;
        transition: all 0.25s ease;
        opacity: 0;
    }
    &.button-large::before {
        border-radius: 40px;
    }
    &.button-blinking::before {
        animation: ${blinking} 1.25s ease infinite;
    }
    &.button-outline,
    &.button-outline-white {
        &::before {
            opacity: 1;
        }
    }
    &:hover,
    &:focus,
    .isopen & {
        text-decoration: none;
        &::before {
            opacity: 1;
        }
        &.button-normal {
            background: #0052b4;
            &:disabled {
                background: #1c287f;
            }
        }
        &.button-action {
            background: #03ce9c;
            &:disabled {
                background: #00c090;
            }
        }
        &.button-red {
            background: #ff0a36;
        }
        &.button-orange {
            background: #ffa04d;
        }
        &.button-clear,
        &.button-outline {
            background: transparent;
        }
        &.button-outline {
            &::before {
                box-shadow: 0 0 0 2px rgba(28, 40, 127, 1);
            }
        }
        &.button-outline-white {
            &::before {
                box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
            }
        }
    }
    &:hover {
        @media (${bp.sm}) {
            :before {
                opacity: 0;
            }
        }
    }
    .isopen & {
        @media (${bp.sm}) {
            :before {
                opacity: 1;
            }
        }
    }
    &:active:not(:disabled) {
        &::before {
            opacity: 0;
        }
        &.button-normal {
            background: #162066;
            color: rgba(255, 255, 255, 0.7);
        }
        &.button-action {
            background: #00926e;
            color: rgba(255, 255, 255, 0.7);
        }
        &.button-clear,
        &.button-outline {
            background: #e9eaf3;
            color: #1c287f;
        }
        &.button-delete {
            background: rgba(218, 0, 38, 0.15);
            color: #d80027;
        }
        &.button-red {
            background: #96001a;
            color: rgba(255, 255, 255, 0.7);
        }
        &.button-orange {
            background: #cc711f;
            color: rgba(255, 255, 255, 0.7);
        }
        &.button-outline {
            &::before {
                opacity: 1;
                box-shadow: 0 0 0 2px rgba(28, 40, 127, 0.5);
            }
        }
        &.button-clear-white {
            background: rgba(255, 255, 255, 0.1);
            &::before {
                opacity: 1;
                box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
            }
        }
        &.button-clear-green {
            background: rgb(0, 192, 144, 0.1);
            &::before {
                opacity: 1;
                box-shadow: 0 0 0 2px rgb(0, 192, 144, 1);
            }
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &::before {
            display: none;
        }
    }
`;
