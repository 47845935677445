import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const useMSTranslation = (ns?: string[]) => {
    if (!ns) ns = ['common'];
    if (ns[0] != 'common') ns.unshift('common');
    const { t, i18n } = useTranslation(ns);

    const __ = useCallback(
        (toTrad: string, ns?: string, interp?: any) => {
            if (!interp) interp = {};
            if (i18n.language == 'fr') {
                for (const i in interp) {
                    if (interp.hasOwnProperty(i)) {
                        toTrad = toTrad.replace('{{' + i + '}}', interp[i]);
                    }
                }
                return toTrad;
            }
            if (ns) return t(ns + '|' + toTrad, interp);
            else return t(toTrad, interp);
        },
        [i18n.language, t]
    );

    const __s = useCallback(
        (
            count: number,
            toTrad: string,
            // @ts-ignore
            toTradPlural?: string,
            ns?: string
        ) => {
            if (toTradPlural && count > 1 && i18n.language == 'fr')
                return toTradPlural.replace('{{count}}', count.toString());
            if (ns) return t(ns + '|' + toTrad, { count: count });
            else return t(toTrad, { count: count });
        },
        [t, i18n.language]
    );

    return { __, __s, i18n };
};
