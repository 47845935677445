import NextI18Next from 'next-i18next';

const NextI18NextInstance = new NextI18Next({
    defaultLanguage: 'fr',
    otherLanguages: ['en'],
    localePath: 'public/locales',
    localeSubpaths: {
        en: 'en',
        fr: 'fr'
    },
    keySeparator: '§',
    nsSeparator: '|'
});

export const {
    appWithTranslation,
    withTranslation,
    Link,
    Router
} = NextI18NextInstance;

module.exports = NextI18NextInstance;
